<template>
  <v-container class="del-pb content-config-list">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card elevation="2" :class="[scroll_data.extended ? (scroll_data.extended && !child_tab.child_visible ? 'card-top card-top-scale pa-0' : 'card-top pa-4') : 'card-bottom pa-4', 'card-content']" tile>
      <span @click.stop="_viewToggle({type:'list'})" class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5">{{ child_tab.child_title }}</span>
      <v-scroll-y-transition hide-on-leave>
        <div v-if="child_tab.child_visible">
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pb">
              <v-col cols="4" class="del-pl">
                <v-text-field v-model="search_data.search" single-line class="del-text-field-msg pa-0" outlined label="请输入内容陈述、创建人" dense clearable prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
              <v-btn class="px-6" color="primary" dark small tile elevation="3" @click="search">搜索</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pt del-pb">
              <span class="text-caption">创建/更新时间</span>
              <v-col cols="4" class="del-pr">
                <v-menu ref="search_data_date" v-model="search_date_visible" :close-on-content-click="false" :return-value.sync="search_date" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field @click:clear="search_date = [];search_data.startDate='';search_data.endDate='';" :value="search_date.length>1?`${search_date[0]} ～ ${search_date[1]}`:''" readonly v-bind="attrs"
                      v-on="on" single-line class="del-text-field-msg pa-0" outlined label="请选择时间" dense :clearable="!search_data.startDate&&!search_data.endDate?false:true" prepend-inner-icon="mdi-calendar">
                    </v-text-field>
                  </template>
                  <v-date-picker locale="zh-cn" v-model="search_date" range no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.stop="search_date_visible = false">
                      取消
                    </v-btn>
                    <v-btn text color="primary" @click.stop="_dateConfirm">
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
            <v-col class="del-pt">
              <v-btn @click.stop="_viewToggle({type:'add'})" class="px-6" color="primary" dark small tile elevation="3">新增</v-btn>
              <v-btn class="ml-4 px-6" color="white" small tile elevation="3" @click="_delContentPresent">删除</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template>
                <div>
                  <v-data-table hide-default-footer no-data-text="暂无数据" @pagination="_paginationAction" :footer-props="{'items-per-page-options':[10]}" v-model="selected_data" :headers="headers" :items="desserts"
                    item-key="id" show-select :server-items-length="server_list_num">
                    <template v-slot:item.action="{ item }">
                      <v-btn x-small elevation="0" color="accent" outlined @click.stop="_viewToggle({type:'edit',item: item})">编辑</v-btn>
                    </template>
                  </v-data-table>
                </div>
                <BasePageTotal :pageData.sync="pageData" @jumpAction="_pageTotalAction" />
              </template>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>
    </v-card>

    <!-- 详情 -->
    <v-scroll-y-reverse-transition hide-on-leave>
      <v-card v-if="!child_tab.child_visible" tile elevation="2" :class="[scroll_data.extended ? 'card-child-top' : 'card-child-bottom', 'pa-4 card-child-content']">
        <div class="w-100 d-flex justify-end">
          <v-btn icon @click.stop="_viewToggle({type:'list'})">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-form ref="content_present">
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"><i></i> <span class="subtitle-1">配置信息</span></span>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">内容陈述</span></span>
                <v-col cols="3" class="del-p">
                  <v-text-field :rules="[rules.required]" v-model="set_post.contentPresent" single-line class="del-text-field-msg pa-0" outlined label="请输入" dense clearable></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">选项1</span></span>
                <v-col cols="3" class="del-p">
                  <v-text-field :rules="[rules.required]" v-model="set_post.optionOne" single-line class="del-text-field-msg pa-0" outlined label="请输入" dense clearable></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">选项2</span></span>
                <v-col cols="3" class="del-p">
                  <v-text-field :rules="[rules.required]" v-model="set_post.optionTwo" single-line class="del-text-field-msg pa-0" outlined label="请输入" dense clearable></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">是否必填</span></span>
                <v-col cols="3" class="del-p">
                  <v-radio-group :rules="[rules.required]" v-model="set_post.fillFlag" row class="del-text-field-msg pa-0 del-mt">
                    <v-radio label="是" :value="'0'"></v-radio>
                    <v-radio label="否" :value="'1'"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">是否有输入框</span></span>
                <v-col cols="3" class="del-p">
                  <v-radio-group :rules="[rules.required]" v-model="set_post.inputFlag" row class="del-text-field-msg pa-0 del-mt">
                    <v-radio label="是" :value="'0'"></v-radio>
                    <v-radio label="否" :value="'1'"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-col>
              <v-col v-if="set_post.inputFlag=='0'" cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">选项触发条件</span></span>
                <v-col cols="3" class="del-p">
                  <v-radio-group :rules="[rules.required]" v-model="set_post.triggerCondition" row class="del-text-field-msg pa-0 del-mt">
                    <v-radio label="选择选项1时" :value="'0'"></v-radio>
                    <v-radio label="选择选项2时" :value="'1'"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-col>
              <v-col v-if="set_post.inputFlag=='0'" cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">输入框提示</span></span>
                <v-col cols="3" class="del-p">
                  <v-text-field :rules="[rules.required]" v-model="set_post.presentation" single-line class="del-text-field-msg pa-0" outlined label="请输入" dense clearable></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"><span class="red--text">*</span><span class="col-label-text">会审部门</span></span>
                <v-col cols="3" class="del-p">
                  <v-select :rules="[rules.required]" v-model="set_post.deptId" class="del-text-field-msg pa-0" dense :items="dept_list" item-text="name" item-value="id" single-line label="请选择" outlined></v-select>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex justify-center del-pt">
                <v-btn @click="_setContentPresent" color="primary" dark small tile elevation="3" class="px-10 py-5">提交</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-scroll-y-reverse-transition>

  </v-container>
</template>

<script>
import BasePageTotal from '@/components/base-page-total.vue';
import {
  getContentPresentList,
  setContentPresent,
  delContentPresent
} from '@/api/api';

export default {
  props: ['scroll_data'],
  data() {
    return {
      pageData: {
        page: 1,
        server_length: 1
      },
      server_list_num: 0,
      search_data: {
        search: ''
      }, //搜索数据
      search_date: [], //选择的时间
      search_date_visible: false, //时间选择器
      selected_data: [], //表格选中数据
      headers: [
        { text: '操作', value: 'action', sortable: false },
        { text: '内容陈述', value: 'contentPresent', sortable: false },
        { text: '是否必填', value: 'fillFlagCn', sortable: false },
        { text: '选项1', value: 'optionOne', sortable: false },
        { text: '选项2', value: 'optionTwo', sortable: false },
        { text: '是否有输入框', value: 'inputFlagCn', sortable: false },
        { text: '触发条件', value: 'triggerConditionCn', sortable: false },
        { text: '创建/更新人', value: 'createUser', sortable: false },
        { text: '创建/更新时间', value: 'createTime', sortable: false },
        { text: '会审部门', value: 'deptName', sortable: false }
      ], //表格表头
      desserts: [], //表格数据
      child_tab: {
        child_visible: true,
        child_title: '内容陈述配置'
      }, //弹出层样式配置
      dept_list: [
        {
          name: '基建办公室',
          id: '250'
        },
        {
          name: '总务与空间办公室',
          id: '238'
        },
        {
          name: '设施设备维护办公室',
          id: '241'
        },
        {
          name: '安全、健康与环境办公室',
          id: '242'
        },
        {
          name: '网络信息中心',
          id: '260'
        }
      ], //部门列表
      set_post: {}, //提交信息
      rules: {
        required: (value) => !!value || '必填'
      }, //填写验证
      edit_data: {}
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    _paginationAction(data) {
      let self = this;
      self.items_per_page = data.itemsPerPage;
      self.pageData.page = data.page;
      self._getContentPresentList(data.page);
    },
    
    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getContentPresentList(page);
    },

    // 试图切换
    _viewToggle({ type, item }) {
      let self = this;
      self.child_tab.child_visible = false;
      switch (type) {
        case 'add':
          self.child_tab.child_title = '新增';
          break;
        case 'edit':
          item.fillFlag =
            item.fillFlag === 0 || item.fillFlag === 1
              ? item.fillFlag.toString()
              : null;
          item.inputFlag =
            item.inputFlag === 0 || item.inputFlag === 1
              ? item.inputFlag.toString()
              : null;
          item.triggerCondition =
            item.triggerCondition === 0 || item.triggerCondition === 1
              ? item.triggerCondition.toString()
              : null;
          self.set_post = item;
          self.child_tab.child_title = '编辑';
          break;
        case 'list':
          self.set_post = {};
          self.child_tab.child_visible = true;
          self.child_tab.child_title = '内容陈述配置';
          self._getContentPresentList();
          break;
        default:
          break;
      }
    },

    // 日期确认
    _dateConfirm() {
      let self = this;
      self.search_date_visible = false;
      if (self.search_date.length <= 1) {
        this.$confirm('请选择俩个日期', '操作失败', {
          confirmButtonText: '确定',
          type: 'error',
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false
        });
      } else {
        self.search_data.startDate = `${self.search_date[0]} 00:00:00`;
        self.search_data.endDate = `${self.search_date[1]} 23:59:59`;
        self.$refs.search_data_date.save(self.search_date);
      }
    },

    /**
     *〈 内容陈述 -> 获取列表 〉
     */
    search(){
      this.pageData.page=1
      this._getContentPresentList();
    },
    _getContentPresentList() {
      let self = this;
      let params = {
        search: self.search_data.search, // 搜索框内容
        startDate: self.search_data.startDate, // 开始时间
        endDate: self.search_data.endDate, // 结束时间
        currentPage: self.pageData.page < 1 ? 1 : self.pageData.page // 当前页:默认值1
        // pageSize: 10, // 每页条数:默认值10
        // deptId: '' // 	部门ID
      };
      getContentPresentList(params)
        .then((res) => {
          if (res.code == '0000') {
            self.desserts = res.data.records;
            self.server_list_num = res.data.total;
            self.pageData.server_length = res.data.pages;
            self.pageData.page = res.data.current;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 提交内容陈述 〉
     */
    _setContentPresent() {
      let self = this;
      if (self.$refs.content_present.validate()) {
        let params = self.set_post;
        params.fillFlag = parseInt(params.fillFlag);
        params.inputFlag = parseInt(params.inputFlag);
        params.triggerCondition = parseInt(params.triggerCondition);
        self.dept_list.forEach((item) => {
          if (item.id == params.deptId) {
            params.deptName = item.name; // 会审部门名称
          }
        });
        params.fillFlagCn = params.fillFlag ? '否' : '是'; // 是否需要输入框
        params.inputFlagCn = params.inputFlag ? '否' : '是'; // 是否需要输入框
        if (params.inputFlag) {
          params.triggerConditionCn = '无';
        } else {
          params.triggerConditionCn = params.triggerCondition
            ? '选项2时触发'
            : '选项1时触发'; // 触发条件中文
        }
        setContentPresent(params)
          .then((res) => {
            if (res.code == '0000') {
              self.$notify({
                title: '操作成功',
                type: 'success'
              });
              self._viewToggle({ type: 'list' });
              setTimeout(() => {
                self.set_post = {};
                // self.$refs.content_present.reset();
              }, 500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    /**
     *〈 内容陈述 -> 删除 〉
     */
    _delContentPresent() {
      let self = this;
      let params = [];
      self.selected_data.forEach((item) => {
        params.push(item.id);
      });
      delContentPresent(params)
        .then((res) => {
          self.selected_data = [];
          if (res.code == '0000') {
            self.$notify({
              title: '操作成功',
              type: 'success'
            });
            self._getContentPresentList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self._getContentPresentList();
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style>
.content-config-list .text-caption {
  display: flex !important;
}
</style>
<style lang="scss" scoped>
</style>
