<template>
  <div>sys info</div>
</template>

<script>
/* import {} from '@/'; */

export default {
  data() {
    return {};
  },
  /* import引入的组件 */
  components: {},
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    getDevice() {
      var browserInfo = {
        versions: (function () {
          var u = navigator.userAgent;
          // app = navigator.appVersion;
          return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1, //是否微信
            qq: u.match(/\sQQ/i) == ' qq' //是否QQ
          };
        })(),

        // detail: navigator.userAgent, //IE内核
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase()
      };
      return browserInfo;
    }
  },
  /* 局部过滤器 */
  filters: {},
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    console.log(this.getDevice());
    console.log( navigator.userAgent);
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style lang='scss' scoped>
</style>
