<template>
  <v-container class="del-pb review-person-config">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card
      elevation="2"
      :class="[
        scroll_data.extended
          ? scroll_data.extended && !child_tab.child_visible
            ? 'card-top card-top-scale pa-0'
            : 'card-top pa-4'
          : 'card-bottom pa-4',
        'card-content',
      ]"
      tile
    >
      <span
        @click.stop="_viewToggle({ type: 'list' })"
        class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5"
        >{{ child_tab.child_title }}</span
      >
      <v-scroll-y-transition hide-on-leave>
        <div v-if="child_tab.child_visible">
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pb">
              <v-col cols="4" class="del-pl">
                <v-text-field
                  v-model="search_data.search"
                  single-line
                  class="del-text-field-msg pa-0"
                  outlined
                  label="请输入姓名、部门"
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-btn
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                @click="_getAssessorList(1)"
                >搜索</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pt del-pb">
              <span class="text-caption">有效日期</span>
              <v-col cols="4" class="del-pr">
                <v-menu
                  ref="search_data_date"
                  v-model="search_date_visible"
                  :close-on-content-click="false"
                  :return-value.sync="search_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      @click:clear="
                        search_date = [];
                        search_data.startDate = '';
                        search_data.endDate = '';
                      "
                      :value="
                        search_date.length > 1
                          ? `${search_date[0]} ～ ${search_date[1]}`
                          : ''
                      "
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      placeholder="请选择时间"
                      dense
                      :clearable="
                        !search_data.startDate && !search_data.endDate
                          ? false
                          : true
                      "
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="zh-cn"
                    v-model="search_date"
                    range
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click.stop="search_date_visible = false"
                    >
                      取消
                    </v-btn>
                    <v-btn text color="primary" @click.stop="_dateConfirm">
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
            <v-col class="del-pt">
              <v-btn
                @click.stop="_viewToggle({ type: 'add' })"
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                >新增</v-btn
              >
              <v-btn
                class="ml-4 px-6"
                color="white"
                small
                tile
                elevation="3"
                @click="_delAssessorList"
                >删除</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template>
                <div>
                  <v-data-table
                    hide-default-footer
                    :footer-props="{ 'items-per-page-options': [10] }"
                    no-data-text="暂无数据"
                    v-model="selected_data"
                    :headers="headers"
                    :items="desserts"
                    item-key="id"
                    show-select
                  >
                    <template v-slot:item.action="{ item }">
                      <v-btn
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="#2D9186"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit', item: item })"
                        >编辑</v-btn
                      >
                      <v-btn
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="#2D9186"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit2', item: item })"
                        >交接待办</v-btn
                      >
                    </template>
                  </v-data-table>
                  <BasePageTotal
                    :pageData.sync="pageData1"
                    @jumpAction="_pageTotalAction1"
                  />
                </div>
              </template>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>
    </v-card>

    <!-- 详情 -->
    <v-scroll-y-reverse-transition hide-on-leave>
      <v-card
        v-if="!child_tab.child_visible"
        tile
        elevation="2"
        :class="[
          scroll_data.extended ? 'card-child-top' : 'card-child-bottom',
          'pa-4 card-child-content',
        ]"
      >
        <div v-if="action_type !== 'edit2'">
          <div class="w-100 d-flex justify-end">
            <v-btn icon @click.stop="_viewToggle({ type: 'list' })">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <v-form ref="project">
              <v-row>
                <v-col cols="12" class="d-flex align-center del-pb">
                  <span class="text-caption px-2 list-item-subtitle"
                    ><i></i> <span class="subtitle-1">配置信息</span></span
                  >
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <span class="text-caption px-2"
                    ><span class="red--text">*</span
                    ><span class="col-label-text">部门名称</span></span
                  >
                  <v-col cols="3" class="del-p">
                    <v-text-field
                      v-if="action_type !== 'add'"
                      disabled
                      v-model="post_data.deptName"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      label="请输入"
                      dense
                      clearable
                    >
                    </v-text-field>
                    <v-select
                      @change="_getUserGroupAction('')"
                      v-if="action_type == 'add'"
                      item-text="name"
                      item-value="id"
                      v-model="post_data.deptId"
                      :items="dept_list"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      label="请选择"
                      dense
                      clearable
                    >
                    </v-select>
                  </v-col>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <span class="text-caption px-2"
                    ><span class="red--text">*</span
                    ><span class="col-label-text">姓名</span></span
                  >
                  <v-col cols="3" class="del-p">
                    <v-text-field
                      @click:append="_deptVisibleToggle"
                      readonly
                      append-icon="mdi-account-multiple"
                      v-model="post_data.assessorName"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      label="请输入"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="12" class="pa-6" v-if="dept_visible">
                  <template>
                    <div>
                      <v-text-field
                        v-model="dept_search"
                        @change="_getUserGroupAction(1)"
                        append-icon="mdi-magnify"
                        label="请输入工号、姓名、部门"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-data-table
                        hide-default-footer
                        :footer-props="{
                          'items-per-page-text': '',
                          'items-per-page-options': [5],
                        }"
                        @update:page="_getUserGroupAction"
                        :server-items-length="person_list_num"
                        :items-per-page="5"
                        no-data-text="暂无数据"
                        :headers="dept_headers"
                        :items="dept_desserts"
                        item-key="sid"
                      >
                        <template v-slot:item.action="{ item }">
                          <v-radio-group
                            class="del-text-field-msg mt-0"
                            v-model="post_data.assessorId"
                          >
                            <v-radio
                              class="del-text-field-msg"
                              :key="item.sid"
                              :value="item.sid"
                              @change="_changePerson(item)"
                            ></v-radio>
                          </v-radio-group>
                        </template>
                      </v-data-table>
                      <BasePageTotal
                        :pageData.sync="pageData"
                        @jumpAction="_pageTotalAction"
                      />
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <span class="text-caption px-2"
                    ><span class="red--text">*</span
                    ><span class="col-label-text">工号</span></span
                  >
                  <v-col cols="3" class="del-p">
                    <v-text-field
                      disabled
                      v-model="post_data.assessorId"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      label="请输入"
                      dense
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <span class="text-caption px-2"
                    ><span class="red--text">*</span
                    ><span class="col-label-text">有效期</span></span
                  >
                  <v-col cols="3" class="del-p">
                    <v-menu
                      ref="post_data_date"
                      v-model="post_date_visible"
                      :close-on-content-click="false"
                      :return-value.sync="post_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          @click:clear="
                            post_date = [];
                            post_data.indateStart = '';
                            post_data.indateEnd = '';
                          "
                          :value="
                            post_date.length > 1
                              ? `${post_date[0]} ～ ${post_date[1]}`
                              : ''
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          single-line
                          class="del-text-field-msg pa-0"
                          outlined
                          placeholder="请选择时间"
                          dense
                          :clearable="
                            !post_data.indateStart && !post_data.indateEnd
                              ? false
                              : true
                          "
                          prepend-inner-icon="mdi-calendar"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="zh-cn"
                        v-model="post_date"
                        range
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="post_date_visible = false"
                        >
                          取消
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="_postDateConfirm"
                        >
                          确定
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
            <v-row
              justify="center"
              class="py-4"
              v-if="action_type == 'detail' ? false : true"
            >
              <v-btn
                @click="_setReviewPersonAction"
                color="primary"
                dark
                small
                tile
                elevation="3"
                class="px-10 py-5 ml-4"
                >提交</v-btn
              >
            </v-row>
          </div>
        </div>
        <div v-else>
          <div>
            <v-form ref="project">
              <v-row>
                <v-col cols="12" class="d-flex align-center del-pb">
                  <span class="text-caption px-2 list-item-subtitle"
                    ><i></i> <span class="subtitle-1">选择人员</span></span
                  >
                </v-col>
                <v-col cols="12" class="d-flex flex-row align-center del-pb">
                  <v-col cols="4" class="del-pl">
                    <v-text-field
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      label="请输入姓名、工号"
                      dense
                      clearable
                      v-model="searchInput"
                      prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    class="px-6"
                    color="primary"
                    dark
                    small
                    tile
                    elevation="3"
                    @click="getHandoverList"
                    >搜索</v-btn
                  >
                </v-col>

                <v-col>
                  <v-data-table
                    hide-default-footer
                    no-data-text="暂无数据"
                    v-model="selected_data_eidt"
                    :headers="edit_headers"
                    item-key="assessorId"
                    show-select
                    :items="filterInfrastructureList"
                  >
                    <!-- :items="filterInfrastructureList" -->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>

            <BasePageTotal
              :pageData.sync="pageDataHandover"
              @jumpAction="handover_pageTotalAction"
            />

            <v-row
              justify="center"
              class="py-4"
              v-if="action_type == 'detail' ? false : true"
            >
              <v-btn
                color="white"
                style="color: #333"
                dark
                small
                tile
                elevation="3"
                class="px-10 py-5 ml-4"
                @click="cancel"
                >取消</v-btn
              >
              <v-btn
                color="primary"
                dark
                small
                tile
                elevation="3"
                class="px-10 py-5 ml-4"
                @click="saveHandover"
                >确定</v-btn
              >
            </v-row>
          </div>
        </div>
      </v-card>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import BasePageTotal from "@/components/base-page-total.vue";

import tools from "@/util/tools";
import {
  getAssessorList,
  delAssessorList,
  setAssessor,
  getDeptUserList,
  getAssessorTransferList,
  assessorTransfer,
} from "@/api/api";

export default {
  props: ["scroll_data"],
  data() {
    return {
      pageData: {
        page: 1,
        server_length: 1,
      },
      pageData1: {
        page: 1,
        server_length: 1,
      },
      pageDataHandover: {
        page: 1,
        size: 10,
        total: 0,
        server_length: 1,
      },
      rules: {
        required: (value) => !!value || "必填",
      },
      search_data: {}, // 搜索数据
      search_date: [], // 搜索时间
      search_date_visible: false, // 选择时间组件

      post_date: [], // 有效期时间
      post_date_visible: false, // 有效期时间组件
      toggle_index: 1, //视图nav id
      headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
        { text: "姓名", value: "assessorName", sortable: false },
        { text: "工号", value: "assessorId", sortable: false },
        { text: "有效期", value: "indate", sortable: false },
        { text: "授权人", value: "certigierName", sortable: false },
        { text: "授权时间", value: "certigierTime", sortable: false },
        { text: "交接待办条数", value: "surplusNum", sortable: false },
        { text: "备注", value: "des", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据
      selected_data: [], // 列表已选数据
      dept_headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "姓名", value: "name", sortable: false },
        { text: "工号", value: "sid", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
      ], // 列表头
      edit_headers: [
        { text: "姓名", value: "assessorName", sortable: false },
        { text: "工号", value: "assessorId", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
      ],
      dept_desserts: [], // 列表数据
      dept_search: "",
      dept_visible: false,
      child_tab: {
        child_visible: true,
        child_title: "会审人员列表",
      }, // 视图
      post_data: {}, // 提交数据
      dept_list: [
        {
          name: "基建办公室",
          id: "250",
        },
        {
          name: "总务与空间办公室",
          id: "238",
        },
        {
          name: "设施设备维护办公室",
          id: "241",
        },
        {
          name: "安全、健康与环境办公室",
          id: "242",
        },
        {
          name: "网络信息中心",
          id: "260",
        },
      ], //部门列表
      action_type: "list", // 当前操作
      person_list_num: -1, // 总数
      selected_data_eidt: [],
      desserts2: [], //过滤
      searchInput: "",
      row: [],
      filterInfrastructureList: [],
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    handover_pageTotalAction(page) {
      this.pageDataHandover.page = page;
      this.getHandoverList();
    },
    // 取消
    cancel() {
      this.child_tab.child_visible = true;
      this.child_tab.child_title = "会审人员配置";
      console.log(this.child_tab.child_title);
    },
    // 交接待办
    saveHandover() {
      // console.log(this.selected_data_eidt);
      if (this.selected_data_eidt.length > 1) {
        this.$notify({
          title: "只能选择一个人！",
          type: "error",
        });
      } else if (this.selected_data_eidt.length == 0) {
        this.$notify({
          title: "请选择一个人！",
          type: "error",
        });
      } else {
        let data = {
          assessorId: this.selected_data_eidt[0].assessorId, //接收人ID
          transferId: this.row.assessorId, //移交人ID
          currentPage: 1,
          pageSize: 10,
          search: "",
          endDate: "",
          startDate: "",
        };
        console.log(this.selected_data_eidt[0]);
        assessorTransfer(data).then((res) => {
          if (res.code == "0000") {
            this.child_tab.child_visible = true;
            this._getAssessorList();
            this.selected_data_eidt = [];
            this.$notify({
              title: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    getHandoverList() {
      let data = {
        assessorId: this.row.assessorId,
        currentPage: this.pageDataHandover.page,
        endDate: "",
        pageSize: this.pageDataHandover.size,
        search: this.searchInput,
        startDate: "",
        transferId: "",
      };
      getAssessorTransferList(data).then((res) => {
        if (res.code == "0000") {
          this.filterInfrastructureList = res.data.records;
          this.pageDataHandover.page = res.data.current;
          this.pageDataHandover.size = res.data.size;
          this.pageDataHandover.total = res.data.total;
          this.pageDataHandover.server_length = res.data.pages;
          // console.log(this.filterInfrastructureList, 3333333333333);
        }
      });
      this.searchInput2 = "";
    },
    // _testAction(val) {
    //   console.log(val);
    // },
    // 试图切换
    _viewToggle({ type }) {
      let self = this;
      self.child_tab.child_visible = false;
      self.action_type = type;
      // self.post_data = {}; // 清空提交数据
      self.set_post = {};
      switch (type) {
        case "add":
          self.child_tab.child_title = "新增";
          break;
        case "edit":
          self.child_tab.child_title = "编辑";
          break;
        case "edit2":
          this.getHandoverList();
          self.child_tab.child_title = "待办交接";
          break;
        case "list":
          self.child_tab.child_visible = true;
          self.dept_visible = false;
          self.child_tab.child_title = "会审人员列表";
          self._getAssessorList(1);
          break;
        default:
          break;
      }
    },

    // 日期确认
    _dateConfirm() {
      let self = this;
      self.search_date_visible = false;
      if (self.search_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.search_data.startDate = `${self.search_date[0]} 00:00:00`;
        self.search_data.endDate = `${self.search_date[1]} 23:59:59`;
        self.$refs.search_data_date.save(self.search_date);
      }
    },

    // 日期确认
    _postDateConfirm() {
      let self = this;
      self.post_date_visible = false;
      if (self.post_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.post_data.indateStart = `${self.post_date[0]} 00:00:00`;
        self.post_data.indateEnd = `${self.post_date[1]} 00:00:00`;
        self.$refs.post_data_date.save(self.post_date);
      }
    },

    // 行操作
    _itemAtcion({ type, item }) {
      let self = this;
      this.row = item;
      switch (type) {
        case "edit":
          self._viewToggle({ type: type });
          self.post_data = item;
          self.post_date[0] = item.indateStart;
          self.post_date[1] = item.indateEnd;
          break;
        case "edit2":
          self._viewToggle({ type: type });
          self.post_data = item;
          break;
        default:
          break;
      }
    },

    _deptVisibleToggle() {
      let self = this;
      self.dept_visible = !self.dept_visible;
      self._getUserGroupAction(1);
    },

    _changePerson(item) {
      let self = this;
      if (item) {
        self.$set(self.post_data, "assessorId", item.sid);
        self.$set(self.post_data, "assessorName", item.name);
        self.$set(self.post_data, "deptName", item.deptName);
      }
    },

    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getUserGroupAction(page);
    },

    _pageTotalAction1(page) {
      let self = this;
      self.pageData.page = page;
      self._getAssessorList(page);
    },

    /**
     *〈 会审人员 -> 获取列表 〉
     */
    _getAssessorList(pages = 1) {
      let self = this;
      let params = {
        currentPage: pages ? pages : 1, // 当前页:默认值1
        pageSize: 10, // 每页条数:默认值10
        search: self.search_data.search, // 搜索框内容
        startDate: self.search_data.startDate, // 开始时间
        endDate: self.search_data.endDate, // 结束时间
      };
      getAssessorList(params)
        .then((res) => {
          if (res.code == "0000") {
            self.desserts = res.data.records;
            self.pageData1.server_length = res.data.pages;
            res.data.records.forEach((item) => {
              item.indate = `${item.indateStart}～${item.indateEnd}`;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 会审人员 -> 编辑 -> 部门人员列表 〉
     */
    _getUserGroupAction(pages) {
      let self = this;
      if (self.action_type == "add" && self.post_data.deptId == undefined) {
        self.$notify({
          title: "操作失败",
          message: "请先选择部门",
          type: "error",
        });
        return;
      }
      let params = {
        currentPage: pages ? pages : 1,
        dept: [self.post_data.deptId],
        pageSize: 5,
        roleId: 1,
        search: self.dept_search,
        userType: 1,
      };
      getDeptUserList(params)
        .then((res) => {
          if (res.code == "0000") {
            self.person_list_num = res.data.total;
            self.dept_desserts = res.data.records;
            self.pageData.server_length = res.data.pages;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 会审人员 -> 提交 〉
     */
    _setReviewPersonAction() {
      let self = this;
      let params = self.post_data;
      params.certigierName = tools.getUsetInfo().name;
      params.indate = undefined;

      params.certigierTime = undefined;
      params.createTime = undefined;
      params.createUser = undefined;
      params.createUserId = undefined;
      params.delFlag = undefined;
      if (params.indateStart == "" || params.indateEnd == "") {
        return self.$notify({
          title: "请填写正确日期",
          type: "error",
        });
      } else if (
        params.assessorId == undefined ||
        params.assessorName == undefined ||
        params.deptId == undefined ||
        params.deptName == undefined ||
        params.indateEnd == undefined ||
        params.indateStart == undefined
      ) {
        return self.$notify({
          title: "请检查填写信息是否完整!",
          type: "error",
        });
      }

      //       params.certigierName = undefined;
      self.$msgbox
        .confirm("确认提交?")
        .then(() => {
          setAssessor(params)
            .then((res) => {
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._viewToggle({ type: "list" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },

    /**
     *〈 会审人员 -> 删除 〉
     */
    _delAssessorList() {
      let self = this;
      let params = [];
      if (self.selected_data.length <= 0) {
        self.$notify({
          title: "没有选择数据",
          type: "error",
        });
        return;
      }
      self.selected_data.forEach((item) => {
        params.push(item.assessorId);
      });
      self.$msgbox
        .confirm("确认删除?")
        .then(() => {
          delAssessorList(params)
            .then((res) => {
              self.selected_data = [];
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._getAssessorList(1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("取消删除");
        });
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self._getAssessorList(1);
    // self.post_data.deptId = tools.getUsetInfo().departmentId
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss">
.review-person-config .text-caption {
  display: flex !important;
}
</style>
<style lang="scss" scoped></style>
