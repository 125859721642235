<template>
  <v-container class="del-pb review-person-config">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card elevation="2" class="card-content card-top pa-4" tile>
      <span class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5">权限管理</span>
      <template>
        <v-card v-loading="auth_loading">
          <v-toolbar color="primary" dark flat class="del-p">
            <!-- <v-icon>mdi-account-group-outline</v-icon> -->
            <!-- <v-toolbar-title class="ml-2">部门信息</v-toolbar-title> -->
            <v-row justify="center">
              <v-col class="d-flex justify-space-between align-center">
                <div>用户组列表</div>
                <v-chip @click="set_role_dialog = true" outlined label color="white" dark small class="ma-1">
                  <v-icon left small>
                    mdi-account-multiple-plus
                  </v-icon>
                  新建用户组
                </v-chip>
              </v-col>
              <v-col class="d-flex justify-space-between align-center">
                <div>用户组成员</div>
                <v-chip :disabled="dept_active.length === 0" @click="_addUserAction" outlined label color="white" dark small class="ma-1">
                  <v-icon left small>
                    mdi-account
                  </v-icon>
                  添加人员
                </v-chip>
              </v-col>
              <v-col class="d-flex justify-space-between align-center">
                <div>权限列表</div>
                <div></div>
              </v-col>
            </v-row>
          </v-toolbar>

          <v-row class="del-m">
            <!-- 分组 -->
            <v-col style="background: #f7f7f7;" cols="4" md="4" class="del-pl del-pr border-r">
              <v-card-text>
                <v-treeview dense :active.sync="dept_active" item-key="id" item-text="roleName" :items="dept_list" activatable color="success" open-on-click transition>
                  <template v-slot:prepend="{ item }">
                    <v-icon v-if="!item.children">
                      mdi-account-multiple
                    </v-icon>
                  </template>
                  <template #append="{item}">
                    <v-icon @click.stop="_delUserGroupAction(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-treeview>
              </v-card-text>
            </v-col>

            <!-- 人员 -->
            <v-col style="background: #f7f7f7;" cols="4" md="4" class="border-r">

              <v-card-text>
                <div v-if="dept_active.length === 0" key="title" class="title font-weight-light grey--text pa-4 text-center">
                  1.请选择部门
                </div>

                <div v-if="(typeof dept_active[0] !== 'undefined')">
                  <v-col cols="12" class="d-flex align-center">
                    <v-col cols="8" class="del-p">
                      <v-text-field v-model="user_list_search" single-line class="del-text-field-msg pa-0" outlined label="请输入工号、姓名" dense clearable>
                      </v-text-field>
                    </v-col>
                    <span class="text-caption px-2">
                      <v-btn color="accent" @click="_getUserRoleAction(1)">搜索</v-btn>
                    </span>
                  </v-col>
                </div>
                <div v-if="dept_active.length !== 0 && user_list.length === 0" key="title" class="title font-weight-light grey--text pa-4 text-center">
                  暂无数据
                </div>

                <template v-if="user_list_copy.length>0">
                  <div v-for="(item, i) in user_list_copy" :key="i">
                    <v-chip class="ma-1" @click:close="_delUserAction(item)" close outlined color="grey" dark small>
                      <v-icon left small>
                        mdi-account
                      </v-icon>
                      <span>{{item.sid}}{{ item.name }}</span>
                    </v-chip>
                  </div>
                </template>
                <template v-else-if="user_list_copy.length===0&&user_list_search!==''&&user_list_search_status">
                  <div class="title font-weight-light grey--text pa-4 text-center">
                    暂无数据
                  </div>
                </template>
                <template v-else>
                  <div v-for="(item, i) in user_list" :key="i">
                    <v-chip class="ma-1" @click:close="_delUserAction(item)" close outlined color="grey" dark small>
                      <v-icon left small>
                        mdi-account
                      </v-icon>
                      <span>{{item.sid}}{{ item.name }}</span>
                    </v-chip>
                  </div>
                </template>

                <v-scroll-x-transition group hide-on-leave>

                </v-scroll-x-transition>

                <BasePageTotal v-if="user_list.length>0" :pageData.sync="user_list_page_Data" @jumpAction="_userListPageDataAction" />

              </v-card-text>
            </v-col>

            <!-- 权限 -->
            <v-col style="background: #f7f7f7;" cols="4" md="4">
              <v-card-text>
                <div v-if="user_menu.length === 0" key="title" class="title font-weight-light grey--text pa-4 text-center">
                  2.请选择权限
                </div>

                <v-treeview v-model="menu_active" dense v-if="user_menu.length>0" open-all item-key="id" selectable item-text="name" :items="user_menu" activatable color="success" open-on-click transition>
                </v-treeview>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="dept_active.length<=0" @click="_saveUserMenuAction" class="white--text" color="green darken-1" depressed>
              <v-icon right>
                mdi-content-save
              </v-icon>
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-card>

    <!-- 创建自定义用户组 -->
    <v-dialog v-model="set_role_dialog" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline">创建用户组</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="用户组名" required v-model="role_name"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="set_role_dialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text @click="_setRoleAction">
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 添加人员 -->
    <v-dialog persistent v-model="add_user_view" max-width="1200px">
      <v-card class="relative" scrollable>
        <v-card-title>
          添加人员
          <v-spacer></v-spacer>
          <v-btn icon @click="_schoolDataAction">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-16">
          <v-row>
            <v-col>
              <v-text-field v-model="user_search" @change="_getUserGroupAction(1)" class="del-text-field-msg ml-3" dense label="搜索学号/姓名" placeholder="请输入学号/姓名" outlined></v-text-field>
              <v-treeview return-object class="del-tree-level" dense v-loading="loading" v-model="school_user_list" selection-type="independent" item-key="sid" selectable item-text="name" :items="school_all_user"
                activatable color="success" open-on-click transition>
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="!item.children">
                    mdi-account
                  </v-icon>
                </template>
                <template v-slot:label="{ item }">
                  ({{item.sid}}) {{item.name}}
                </template>
              </v-treeview>
              <BasePageTotal class="pb-4 auth-page" :pageData.sync="pageData" @jumpAction="_pageTotalAction" />
            </v-col>
            <v-col>
              <div v-if="school_user_active.length === 0" key="title" class="title font-weight-light grey--text pa-4 text-center">
                请选择人员
              </div>

              <v-scroll-x-transition group hide-on-leave>
                <div v-for="(item, i) in school_user_active" :key="i">
                  <v-chip @click:close="_delUserAction(item)" close outlined color="grey" dark small class="ma-1">
                    <v-icon left small>
                      mdi-account
                    </v-icon>
                    {{item.sid}} {{ item.name }}
                  </v-chip>
                </div>
              </v-scroll-x-transition>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="absolute w-100 white bottom-0">
          <v-col cols="3" class="ma-auto">
            <v-btn block color="primary" @click="_confirmSchoolUserAction">
              提交
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
// import AppBar from '@/components/app-bar.vue';
import BasePageTotal from '@/components/base-page-total.vue';

import {
  getDeptTree,
  getDeptUserList,
  getRoleList,
  getUserRole,
  getUserMenu,
  saveUserMenu,
  insertUserRole,
  deleteUserRole,
  setRole,
  delSysRole
} from '@/api/api';

export default {
  props: ['scroll_data'],
  data() {
    return {
      pageData: {
        page: 1,
        server_length: 1
      },
      user_list_page_Data: {
        page: 1,
        server_length: 1
      },
      user_search: '',
      user_list_search: '',
      dept_list: [],
      dept_active: [],

      user_list: [],
      user_list_copy: [],
      user_active: [],
      user_menu: [],
      user_list_search_status: false,

      school_all_user: [],
      school_user_list: [],
      school_user_active: [],

      add_user_view: false,
      set_role_dialog: false,
      role_name: '',

      menu_active: [],

      loading: false,
      auth_loading: false,
      post_data: {}, // 提交数据
      dept_tree: '',
      selete_item: '',
      dept_person: [],
      select_dept_person: [],

      breweries: [],
      isLoading: false,
      tree: [],
      types: [],
      items: []
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {
    school_user_list() {
      // if (this.school_user_active.length > 0) {
      //   if (this.school_user_list.length > 0) {
      //     this.school_user_active.push(
      //       this.school_user_list[this.school_user_list.length - 1]
      //     );
      //   }
      // } else {
      //   this.school_user_active = this.school_user_list;
      // }
      this.school_user_active = this.school_user_list;
    },
    dept_active() {
      let self = this;
      self.user_list_search = '';
      self.user_list_search_status = false;
      self._getUserRoleAction(1);
      self._getUserMenuAction();
    },
    menu_active(val) {
      let self = this;
      if (val.length > 0) {
        self.user_menu.forEach((menu) => {
          menu.checked = false;
        });
        val.forEach((item) => {
          self.user_menu.forEach((menu) => {
            if (item == menu.id) {
              menu.checked = true;
            }
          });
        });
      }
    }
  },
  /* 方法集合 */
  methods: {
    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getUserGroupAction(page);
    },
    _userListPageDataAction(page) {
      let self = this;
      self.user_list_page_Data.page = page;
      self._getUserRoleAction(page);
    },

    _delUserAction(item) {
      let self = this;
      self
        .$confirm('确认删除?', {
          confirmButtonText: '确定',
          center: true,
          closeOnClickModal: false
        })
        .then(() => {
          self._deleteUserRoleAction(item);
        })
        .catch(() => {});
      // self.user_list.forEach((user, index) => {
      //   if (user.sid == item.sid) {
      //     self.user_list.splice(index, 1);
      //   }
      // });
    },

    _addUserAction() {
      let self = this;
      self.add_user_view = true;
      self._getUserGroupAction();
    },

    //提交新增人员
    _confirmSchoolUserAction() {
      let self = this;
      // self.add_user_view
      if (self.school_user_active.length <= 0) {
        return self.$alert('请选择人员');
      } else {
        self._insertUserRoleAction(); //保存用户组用户
        // self.school_user_active.forEach((item) => {
        //   self.user_list.push(item);
        // });
        // for (var i = 0; i < self.user_list.length; i++) {
        //   var item = self.user_list[i];
        //   for (var j = i + 1; j < self.user_list.length; j++) {
        //     var user = self.user_list[j];
        //     if (item.sid == user.sid) {
        //       self.user_list.splice(j, 1);
        //       i--;
        //     }
        //   }
        // }
      }
    },

    _schoolDataAction() {
      let self = this;
      self.add_user_view = false;
      self.school_user_active = [];
      self.school_user_list = [];
      self.pageData = {
        page: 1,
        server_length: 1
      };
    },

    /**
     *〈 获取所有部门 〉
     */
    _getDeptTreeAction() {
      // let self = this;
      getDeptTree()
        .then((res) => {
          if (res.code == '0000') {
            console.log('-');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 部门人员列表 〉
     */
    _getUserGroupAction(page = 1) {
      let self = this;
      let params = {
        currentPage: page ? page : 1,
        search: self.user_search,
        dept: [],
        pageSize: 10,
        roleId: 1,
        userType: 1
      };
      getDeptUserList(params)
        .then((res) => {
          self.loading = false;
          if (res.code == '0000') {
            self.pageData.server_length = res.data.pages;
            self.school_all_user = res.data.records;
          }
        })
        .catch((err) => {
          self.loading = false;
          console.log(err);
        });
    },

    /**
     *〈 获取自定义用户组 〉
     */
    _getRoleListAction() {
      let self = this;
      let params = {
        currentPage: 1,
        pageSize: 999
      };
      getRoleList(params)
        .then((res) => {
          if (res.code == '0000') {
            self.dept_list = res.data.records;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 获取自定义用户组人员 〉
     */
    _getUserRoleAction(page) {
      let self = this;
      let params = {
        currentPage: page ? page : 1,
        //pageSize
        //roleId
        search: self.user_list_search,
        roleId: self.dept_active[0]
      };
      if (typeof self.dept_active[0] == 'undefined') {
        self.user_list = [];
        return;
      }
      getUserRole(params)
        .then((res) => {
          if (res.code == '0000') {
            self.user_list_page_Data.server_length = res.data.pages;
            if (res.data.pages === 0) {
              self.user_list_page_Data.server_length = 1;
            }
            if (self.user_list_search !== '') {
              self.user_list_search_status = true;
              self.user_list_copy =
                res.data.records.length > 0 ? res.data.records : [];
            } else {
              self.user_list_copy = [];
              self.user_list = res.data.records;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 获取自定义用户组权限 〉
     */
    _getUserMenuAction() {
      let self = this;
      let params = {
        roleId: self.dept_active[0]
      };
      if (typeof self.dept_active[0] == 'undefined') {
        self.user_menu = [];
        return;
      }
      self.auth_loading = true;
      getUserMenu(params)
        .then((res) => {
          self.auth_loading = false;
          if (res.code == '0000') {
            self.user_menu = [];
            self.menu_active = [];
            res.data.forEach((menu) => {
              if (menu.checked) {
                self.menu_active.push(menu.id);
              }
            });
            self.user_menu = res.data;
          }
        })
        .catch((err) => {
          self.auth_loading = false;
          console.log(err);
        });
    },

    /**
     *〈 保存权限 〉
     */
    _saveUserMenuAction() {
      let self = this;
      let params = { menuIds: self.menu_active, roleId: self.dept_active[0] };
      self.auth_loading = true;
      saveUserMenu(params)
        .then((res) => {
          self.auth_loading = false;
          self.user_list_search = '';
          self.user_menu = [];
          self.menu_active = [];
          self.user_list = [];
          self.user_list_copy = [];
          if (res.code == '0000') {
            self.$notify({
              title: '保存成功',
              type: 'success'
            });
            self._getUserRoleAction(1);
            self._getUserMenuAction();
          }
        })
        .catch((err) => {
          self.user_list_copy = [];
          self.user_list_search = '';
          self.user_list = [];
          self.user_menu = [];
          self.menu_active = [];
          self.auth_loading = false;
          console.log(err);
        });
    },

    /**
     *〈 新增用户组用户 〉
     */
    _insertUserRoleAction() {
      let self = this;
      if (self.dept_active.length <= 0) {
        return self.$alert('请选择操作部门');
      }
      let params = { roleId: self.dept_active[0], userIds: [] };
      self.school_user_active.forEach((user) => {
        params.userIds.push(user.sid);
      });
      self.auth_loading = true;
      insertUserRole(params)
        .then((res) => {
          self.auth_loading = false;
          if (res.code == '0000') {
            self.add_user_view = false;
            self.school_user_active = [];
            self.school_user_list = [];
            self.user_list_page_Data = {
              page: 1,
              server_length: 1
            };
          }
        })
        .then(() => {
          // self._saveUserMenuAction();
          self._getUserRoleAction(1);
        })
        .catch((err) => {
          self.auth_loading = false;
          console.log(err);
        });
    },

    /**
     *〈 删除用户组用户 〉
     */
    _deleteUserRoleAction(user) {
      let self = this;
      let params = { roleId: self.dept_active[0], userIds: [user.sid] };
      self.auth_loading = true;
      deleteUserRole(params)
        .then(() => {
          self.auth_loading = false;
          self.user_list_page_Data = {
            page: 1,
            server_length: 1
          };
          self._getUserRoleAction(1);
        })
        .catch((err) => {
          self.auth_loading = false;
          console.log(err);
        });
    },

    /**
     *〈 创建用户组 〉
     */
    _setRoleAction() {
      let self = this;
      if (self.role_name == '') {
        self.$notify({
          title: '请输入用户组名',
          type: 'error'
        });
        return;
      }
      let params = {
        roleName: self.role_name
      };
      self.set_role_dialog = false;
      self.auth_loading = true;
      setRole(params)
        .then((res) => {
          self.auth_loading = false;
          if (res.code == '0000') {
            self.$notify({
              title: '创建成功',
              type: 'success'
            });
          }
        })
        .then(() => {
          self._getRoleListAction();
        })
        .catch((err) => {
          self.auth_loading = false;
          console.log(err);
        });
    },

    /**
     *〈 删除用户组 〉
     */
    _delUserGroupAction(user) {
      let self = this;
      let params = { id: user.id };
      this.$confirm('确认删除?', {
        confirmButtonText: '确定',
        center: true,
        closeOnClickModal: false
      })
        .then(() => {
          delSysRole(params)
            .then((res) => {
              if (res.code == '0000') {
                self.$notify({
                  title: '删除成功',
                  type: 'error'
                });
                self.user_list = [];
                self.user_list_search = '';
                self.dept_active = [];
                self._getRoleListAction();
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self._getRoleListAction();
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style lang="scss" >
.review-person-config .text-caption {
  display: flex !important;
}
.auth-page .v-pagination {
  justify-content: start !important;
}
.del-tree-level .v-treeview-node__level {
  display: none !important;
}
.del-tree-level .v-treeview-node__root {
  padding-left: 4px !important;
}
</style>
<style lang="scss" scoped>
</style>
