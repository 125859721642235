<template>
  <v-container class="del-pb">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card
      elevation="2"
      :class="[
        scroll_data.extended
          ? scroll_data.extended && !child_tab.child_visible
            ? 'card-top card-top-scale pa-0'
            : 'card-top pa-4'
          : 'card-bottom pa-4',
        'card-content',
      ]"
      tile
    >
      <span
        @click.stop="_viewToggle({ type: 'list' })"
        class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5"
        >{{ child_tab.child_title }}</span
      >
      <v-scroll-y-transition hide-on-leave>
        <div v-if="child_tab.child_visible">
          <v-tabs
           
            v-model="toggle_index"
            background-color="#fff"
            color="primary"
            left
            class="border-b"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="nav in list_nav" :key="nav.name">
              {{ nav.name }}
            </v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pb">
              <v-col cols="4" class="del-pl">
                <v-text-field
                  v-model="search_data.search"
                  single-line
                  class="del-text-field-msg pa-0"
                  outlined
                  label="请输入编号、工程名称、改造地点、申请人"
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-btn
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                @click="search"
                >搜索</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pt del-pb">
              <span class="text-caption">申请时间</span>
              <v-col cols="4" class="del-pr">
                <v-menu
                  ref="search_data_date"
                  v-model="search_date_visible"
                  :close-on-content-click="false"
                  :return-value.sync="search_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      @click:clear="
                        search_date = [];
                        search_data.startDate = '';
                        search_data.endDate = '';
                      "
                      :value="
                        search_date.length > 1
                          ? `${search_date[0]} ～ ${search_date[1]}`
                          : ''
                      "
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      placeholder="请选择时间"
                      dense
                      :clearable="
                        !search_data.startDate && !search_data.endDate
                          ? false
                          : true
                      "
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="zh-cn"
                    v-model="search_date"
                    range
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click.stop="search_date_visible = false"
                    >
                      取消
                    </v-btn>
                    <v-btn text color="primary" @click.stop="_dateConfirm">
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template>
                <div>
                  <v-data-table
                    v-if="nowShow"
                    hide-default-footer
                    :footer-props="{ 'items-per-page-options': [10] }"
                    no-data-text="暂无数据"
                    v-model="selected_data"
                    :headers="headers"
                    :items="desserts"
                    item-key="project.id"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        class="ml-1"
                        v-if="toggle_index == 0"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="
                          _itemAtcion({ type: 'detail', item: item })
                        "
                        >详情</v-btn
                      >
                      <v-btn
                        class="ml-1"
                        v-if="toggle_index == 0"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="withdrawApproval(item)"
                        >撤回审批</v-btn
                      >
                      <v-btn
                        v-if="
                          item.project.auditStatusCode == 2 &&
                          toggle_index == 1 &&
                          !item.isShow &&
                          !item.isShow2 &&
                          !item.show
                        "
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="#2D9186"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit', item: item })"
                        >审批</v-btn
                      >
                      <!-- 新增 -->
                      <v-btn
                        @click="is_opinion = 1"
                        v-if="
                          toggle_index == 1 &&
                          (item.isShow || item.isShow2 || item.show)
                        "
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="#2D9186"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit', item: item })"
                        >写意见</v-btn
                      >
                      <v-btn
                        class="ml-1"
                        v-if="item.project.auditStatusCode == 3"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="
                          _itemAtcion({ type: 'export', item: item })
                        "
                        >导出</v-btn
                      >
                    </template>
                  </v-data-table>
                </div>
                <BasePageTotal
                  :pageData.sync="pageData"
                  @jumpAction="_pageTotalAction"
                />
              </template>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>
    </v-card>

    <!-- 详情 -->
    <v-scroll-y-reverse-transition hide-on-leave>
      <v-card
        v-if="!child_tab.child_visible"
        tile
        elevation="2"
        :class="[
          scroll_data.extended ? 'card-child-top' : 'card-child-bottom',
          'pa-4 card-child-content',
        ]"
      >
        <div class="w-100 d-flex justify-end">
          <v-btn icon @click.stop="_viewToggle({ type: 'list' })">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-form ref="project">
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">项目信息</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>项目编号</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    disabled
                    v-model="post_data.project.projectSerial"
                    single-line
                    class="del-text-field-msg pa-0 text-caption"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>工程名称</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.projectName"
                    single-line
                    class="del-text-field-msg pa-0 text-caption"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>改造地点</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.location"
                    single-line
                    class="del-text-field-msg pa-0 text-caption"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>改造内容</span
                >
                <v-col cols="3" class="del-p">
                  <v-textarea
                    :readonly="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.retrofitContent"
                    class="del-text-field-msg text-caption"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- 新增 -->
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>申请部门类型</span
                >
                <v-col cols="3" class="del-p">
                  <v-select
                    :label="post_data.project.deptType"
                    readonly
                    :item="post_data.project.deptType"
                    dense
                    solo
                    class="del-text-field-msg pa-0"
                    style="width: 94%"
                  ></v-select>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>
                  <span style="letter-spacing: 2px">申请人部门</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-select
                    :label="deptName"
                    readonly
                    :item="deptName"
                    dense
                    solo
                    class="del-text-field-msg pa-0"
                    style="width: 94%"
                  ></v-select>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2">
                <span class="red--text">*</span>
                <span v-html="applyNameHtml"></span>
                </span>
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.applyName"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>申请人手机号</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.applyPhone"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>

              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>施工图</span
                >
              </v-col>
              <v-col cols="12" class="del-pt">
                <p
                  class="ma-2"
                  v-for="item in post_data.fileVos"
                  :key="item.url"
                >
                  <span class="text-caption">{{ item.fileName }}</span>
                  <v-icon class="mx-4 cursor-p" @click="_openAction(item)"
                    >mdi-file-eye-outline</v-icon
                  >
                  <span
                    class="
                      text-caption
                      blue--text
                      text-decoration-underline
                      cursor-p
                    "
                    @click="_getDownloadByUrlAndNameAction(item)"
                    >下载</span
                  >
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">项目内容陈述</span></span
                >
              </v-col>

              <v-col cols="12" class="d-flex align-center">
                <v-expansion-panels popout :value="expansion_index">
                  <div
                    class="w-100"
                    v-for="(item, i) in content_present_list"
                    :key="i"
                  >
                    <v-expansion-panel
                      v-if="
                        action_type == 'detail' ||
                        (item.id == departmentId && action_type == 'edit')
                      "
                    >
                      <v-expansion-panel-header>
                        <span class="text-caption">{{ item.name }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p v-if="item.data.length <= 0" class="text-caption">
                          暂无数据
                        </p>
                        <div v-for="child in item.data" :key="child.id">
                          <div
                            v-if="child.checkOption"
                            class="d-flex justify-space-between"
                          >
                            <span class="text-caption"
                              ><span
                                v-if="child.fillFlagCn == '是'"
                                class="red--text"
                                >*</span
                              >{{ child.contentPresent }}</span
                            >
                            <v-radio-group
                              :disabled="
                                action_type == 'detail' || action_type == 'edit'
                                  ? true
                                  : false
                              "
                              v-model="child.checkOption"
                              row
                              class="del-text-field-msg pa-0 del-mt"
                            >
                              <v-radio
                                :click="(child.contentPresentId = child.id)"
                                :label="child.optionOne"
                                :value="'0'"
                              ></v-radio>
                              <v-radio
                                :click="(child.contentPresentId = child.id)"
                                :label="child.optionTwo"
                                :value="'1'"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div class="pt-1" v-if="child.inputContent !== null">
                            <v-text-field
                              readonly
                              v-model="child.inputContent"
                              :placeholder="child.presentation"
                              single-line
                              class="del-text-field-msg pa-0"
                              outlined
                              dense
                              clearable
                            ></v-text-field>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">单位信息</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">使用单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.userDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">造价咨询单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.costConsultDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">设计单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.designDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">监理单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="
                      action_type == 'detail' || action_type == 'edit'
                        ? true
                        : false
                    "
                    v-model="post_data.project.supervisionDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
            <template>
              <!--  v-if="action_type=='detail'||action_type=='edit'" -->
              <v-row
                class="detail-table-style"
                v-for="(item, index) in examine_records_desserts"
                :key="item[index]?item[index].id:index+5645648"
              >
                <v-col cols="12" class="d-flex align-center del-pb">
                  <span class="text-caption px-2 list-item-subtitle"
                    ><i></i>
                    <span class="subtitle-1"
                      >会审记录<span class="red--text font-weight-bold ml-2"
                        >(第 {{ index + 1 }} 轮)</span
                      ></span
                    ></span
                  >
                </v-col>
                <v-col>
                  <template>
                    <div>
                      <v-data-table
                        no-data-text="暂无数据"
                        hide-default-footer
                        :items-per-page="30"
                        dense
                        :headers="examine_records_headers"
                        :items="examine_records_desserts[index]"
                        item-key="id"
                      >
                        <template v-slot:item.auditFlag="{ item }">
                          <span>{{
                            item.auditFlag == "5"
                              ? "审批人撤回"
                              : item.auditFlag
                          }}</span>
                        </template>
                      </v-data-table>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </template>
            <v-row
              class="detail-table-style"
              v-if="action_type == 'detail' ? false : true && !is_opinion"
            >
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">会审批准</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">会审意见</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-radio-group
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_audit.auditFlag"
                    row
                    class="del-text-field-msg pa-0 del-mt"
                  >
                    <v-radio
                      class="radio-width"
                      label="同意"
                      :value="'0'"
                    ></v-radio>
                    <v-radio
                      class="radio-width"
                      label="不同意"
                      :value="'1'"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">意见内容</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-textarea
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="editContent"
                    class="del-text-field-msg"
                    outlined
                    clearable
                  ></v-textarea>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="col-label-text">温馨提示</span></span
                >
                <v-col cols="8" class="del-p text-left">
                  <span class="red--text">{{ review_tips }}</span>
                </v-col>
              </v-col>
            </v-row>
            <!-- 新增 -->
            <v-row class="detail-table-style" v-if="is_opinion">
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">意见栏</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">意见内容</span></span
                >
                <v-col cols="8" class="del-p">
                  <v-textarea
                    rows="2"
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="content"
                    class="del-text-field-msg"
                    outlined
                    clearable
                  ></v-textarea>
                </v-col>
              </v-col>
            </v-row>
          </v-form>
          <v-row
            justify="center"
            class="py-4"
            v-if="action_type == 'detail' ? false : true"
          >
            <v-btn
              @click="back"
              color="primary"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              v-if="is_opinion"
              >退回</v-btn
            >
            <v-btn
              @click="_setProjectAction"
              color="primary"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              >提交</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import BasePageTotal from "@/components/base-page-total.vue";
import {
  getProjectList,
  getContentPresentList,
  getProjectDetail,
  delProject,
  getExportProject,
  setProjectAudit,
  getDownloadByUrlAndName,
  getInfrastructureList,
  withdraw,
  isInfrastructure,
  getIdentificationList,
  returnInfrastructure,
} from "@/api/api";

import tools from "@/util/tools";

export default {
  props: ["scroll_data"],
  data() {
    return {
      applyNameHtml:'申&nbsp;&nbsp;&nbsp;&nbsp;请&nbsp;&nbsp;&nbsp;&nbsp;人&nbsp;',
      pageData: {
        page: 1,
        server_length: 1,
      },
      rules: {
        required: (value) => !!value || "必填",
      },
      search_data: {}, // 搜索数据
      search_date: [], // 搜索时间
      search_date_visible: false, // 选择时间组件
      toggle_index: 1, //视图nav id
      list_nav: [{ name: "已办事项" }, { name: "待办事项" }], // 视图nav
      headers: [
        { text: "操作", value: "actions", sortable: false },
        { text: "编号", value: "project.projectSerial", sortable: false },
        { text: "工程名称", value: "project.projectName", sortable: false },
        { text: "改造地点", value: "project.location", sortable: false },
        { text: "申请人", value: "project.createUser", sortable: false },
        { text: "申请时间", value: "project.createTime", sortable: false },
        { text: "状态", value: "project.auditStatusCn", sortable: false },
        { text: "本轮会审情况", value: "currentExamine", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据

      examine_records_headers: [
        {
          text: "审批步骤",
          value: "isInfrastructureName",
          sortable: false,
          width: 200,
        },
        { text: "部门", value: "deptName", sortable: false, width: 200 },
        { text: "会审人", value: "user", sortable: false, width: 100 },
        {
          text: "会审时间",
          value: "meetingAuditTime",
          sortable: false,
          width: 200,
        },
        { text: "会审意见", value: "auditFlag", sortable: false, width: 200 },
        { text: "意见内容", value: "content", sortable: false },
      ], // 会审记录 - 列表头
      examine_records_desserts: [], // 会审记录 - 列表数据
      review_tips: "",

      selected_data: [], // 列表已选数据
      child_tab: {
        child_visible: true,
        child_title: "会审列表",
      }, // 视图
      content_present_list: [], // 陈述列表
      post_data: {
        project: {},
        projectPresents: [],
      }, // 提交数据
      post_audit: {},
      work_draw: [], // 施工图文件
      expansion_index: "", // 陈述列表展开id
      action_type: "list", // 当前操作
      departmentId: tools.getUsetInfo().departmentId,
      dept_tips: [
        { id: "250", name: "基建办公室 ", text: "结构、承重及外立面情况" },
        { id: "238", name: "总务与空间办公室 ", text: "装修区域空间情况" },
        { id: "241", name: "设备设施维护办公室 ", text: "水电气、空调等情况" },
        { id: "242", name: "安全、健康与环境办公室 ", text: "消防设备情况" },
        { id: "260", name: "网络信息中心 ", text: "弱电情况" },
      ], // 会审提示
      project_id: "", // 操作项目ID
      is_opinion: 0, //是否为写意见
      infrastructureList: [],
      flagBtn: false,
      content: "",
      lastUserShow: "",
      lastUserShowArr: [],
      isShow: "",
      nowShow: false,
      editContent: "",
      isEditNow: [],
      flag: 0,
      deptName: "",
      haveToDo: "", //已办
      toggle_indexShow:false,
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {
    toggle_index(val, oldVal){
      console.log("inputVal = " + val + " , oldValue = " + oldVal);
      // this.pageData.page=1;
     this.toggle_indexShow=true;
      console.log(this.pageData,'this.pageData')
       this._getProjectList(1);
      
    }
  },
  /* 方法集合 */
  methods: {
    // 退回
    back() {
      let data = {
        projectId: this.post_data.project.id,
        content: this.content,
      };
      // console.log(data);
      if (this.content == "") {
        this.$notify({
          title: "请填写退回意见！",
          type: "error",
        });
      } else {
        returnInfrastructure(data).then((res) => {
          if (res.code == "0000") {
            this.$notify({
              title: "操作成功",
              type: "success",
            });
            this._viewToggle({ type: "list" });
          }
        });
      }
    },
    changeNavList() {
      this._getProjectList(1);
    },
    // 撤回审批
    withdrawApproval(item) {
      let projectSerial = item.project.projectSerial;
      console.log(item.project.projectSerial,'==>');
      this.$msgbox
        .confirm("您确定要撤回本轮审批意见吗?")
        .then(() => {
          withdraw({ projectSerial })
            .then((res) => {
              if (res.code == "0000") {
                this.$notify({
                  title: "操作成功",
                  type: "success",
                });
              }
              this._getProjectList();
              sessionStorage.setItem("isEditNow", JSON.stringify([]));
            })
            .catch((err) => {
              this.$notify({
                title: "操作失败",
                message: err.msg,
                type: "error",
              });
              // console.log(err);
            });
        })
        .catch(() => {
          console.log("取消撤回");
        });
    },
    // 试图切换
    _viewToggle({ type }) {
      let self = this;
      self.child_tab.child_visible = false;
      self.action_type = type;
      self.post_data = {
        project: {},
        projectPresents: [],
      }; // 清空提交数据
      self.examine_records_desserts = [];
      switch (type) {
        case "add":
          self.child_tab.child_title = "新增";
          self._getContentPresentList();
          break;
        case "edit":
          self.child_tab.child_title = "会审";
          self._getContentPresentList();
          break;
        case "detail":
          self.child_tab.child_title = "详情";
          self._getContentPresentList();
          break;
        case "list":
          self.set_post = {};
          self.child_tab.child_visible = true;
          self.child_tab.child_title = "会审列表";
          self._getProjectList();
          break;
        default:
          break;
      }
    },

    // 陈述选择验证
    _radioVerifyAction() {
      let self = this;
      let radioVerify = true;
      self.content_present_list.forEach((item, index) => {
        if (!radioVerify) {
          return;
        }
        if (item.data.length > 0) {
          item.data.forEach((list) => {
            if (list.fillFlagCn == "是" && !list.checkOption) {
              self.expansion_index = index;
              self.$notify({
                title: item.name,
                message: `${list.contentPresent}`,
                type: "error",
              });
              radioVerify = false;
              return;
            }
          });
        }
      });
      return radioVerify;
    },

    // 日期确认
    _dateConfirm() {
      let self = this;
      self.search_date_visible = false;
      if (self.search_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.search_data.startDate = `${self.search_date[0]} 00:00:00`;
        self.search_data.endDate = `${self.search_date[1]} 23:59:59`;
        self.$refs.search_data_date.save(self.search_date);
      }
    },

    // 行操作
    _itemAtcion({ type, item }) {
      let self = this;
      this.content = "";
      switch (type) {
        case "edit":
          self._viewToggle({ type: type });
          self.project_id = item.project.id;
          break;
        case "detail":
          self._viewToggle({ type: type });
          self.project_id = item.project.id;
          break;
        case "export":
          self._downloadPdf({ id: item.project.id });
          break;

        default:
          break;
      }
    },

    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getProjectList(page);
    },

    // 预览文件
    _openAction(file) {
      let self = this;
      if (!file.url) {
        self.$notify({
          title: `无预览文件`,
          type: "error",
        });
      } else {
        window.open(file.url);
      }
    },
    objToArr(obj) {
      var arr = [];
      for (var i in obj) {
        var newObj = {};
        newObj[i] = obj[i];
        arr.push(newObj);
      }
      return arr;
    },
    /**
     *〈 会审批准 -> 获取项目列表 〉
     */
    search() {
      this.pageData.page = 1;
      this._getProjectList();
    },
    _getProjectList() {
      let self = this;
      this.nowShow = false;
      let params = {
        // auditStatusCode: self.toggle_index, // 审核状态 0-草稿 1-退回修改 2-会审中 3-会审通过 4-终止
        currentPage:this.toggle_indexShow?'1': self.pageData.page, // 当前页:默认值1
        pageSize: 10, // 每页条数:默认值10
        search: self.search_data.search, // 搜索框内容
        startDate: self.search_data.startDate, // 开始时间
        endDate: self.search_data.endDate, // 结束时间
        todoFlag: self.toggle_index + 1, // 	1-已办 2-待办
        createTimeSortType: "DESC", // 结束时间
      };

      console.log(this.toggle_indexShow,params,'99999')
      this.toggle_indexShow=false
      
      getProjectList(params)
        .then((res) => {
          // console.log(this.toggle_index);
          if (res) {
            self.desserts = res.data;
            // self.pageData.server_length = res.currentPage;
            self.pageData.page = res.currentPage;
            self.pageData.server_length = Math.ceil(res.total / res.pageSize);
            let isEditNow = JSON.parse(sessionStorage.getItem("isEditNow"));
            // console.log(isEditNow);
            // let arr = [];

            getIdentificationList().then((res) => {
              if (res.code == "0000") {
                // console.log(res.data);
                //  console.log();
                this.flag = this.objToArr(res.data);
              }
            });
            let arr = [];
            this.desserts.forEach((item) => {
              isInfrastructure(item.project.id).then((res) => {
                item.isShow = false;
                if (res.code == "0000") {
                  item.isShow = res.data;
                  // console.log(res.data)
                }
              });
              isEditNow.forEach((item2) => {
                // console.log(item2, item.project.id);
                if (item.project.id == item2) {
                  item.isShow2 = true;
                  // console.log(item.isShow2);
                }
              });
            });
            this.haveToDo = this.desserts; //已办

            // this.desserts = arr;
            // console.log(this.desserts);

            // if (item.project.id) {
            // }

            setTimeout(() => {
              this.flag.forEach((item) => {
                for (var i in item) {
                  item.id = i;
                  item.show = item[i];
                }
              });

              this.nowShow = true;
              this.desserts.forEach((item) => {
                this.flag.forEach((item2) => {
                  if (item.project.id == item2.id) {
                    item.show = item2.show;
                  }
                });
                // console.log(item)
              });

              this.desserts.forEach((item) => {
                if (item.isShow || !item.show) {
                  arr.push(item);
                }
              });
              // console.log(this.desserts, 111111111);
              // if (params.todoFlag != 1) {
              //   this.desserts = arr;
              // }
            }, 500);
          }
          // console.log(this.desserts);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 内容陈述 -> 获取列表 〉
     */
    _getContentPresentList() {
      let self = this;
      let params = {
        currentPage: 1, // 当前页: 默认值1
        pageSize: 999, // 每页条数: 默认值10
      };
      getContentPresentList(params)
        .then((res) => {
          if (res.code == "0000") {
            let deptArray = [
              { name: "基建办公室", id: "250", data: [] },
              { name: "总务与空间办公室", id: "238", data: [] },
              { name: "设施设备维护办公室", id: "241", data: [] },
              { name: "安全、健康与环境办公室", id: "242", data: [] },
              { name: "网络信息中心", id: "260", data: [] },
            ];
            res.data.records.forEach((item) => {
              deptArray.forEach((dept) => {
                if (item.deptId == dept.id) {
                  dept.data.push(item);
                }
              });
            });
            self.content_present_list = deptArray;
          }
        })
        .then(() => {
          let self = this;
          if (self.project_id) {
            self._getProjectDetail({ id: self.project_id });
          }
        })
        .catch((err) => {
          console.log(err);
          self.$notify({
            title: err.msg,
            type: "error",
          });
        });
    },

    /**
     *〈 会审批准 -> 详情 〉
     */
    _getProjectDetail({ id }) {
      this.lastUserShowArr = [];
      let self = this;
      let params = { id: id };
      self.post_audit = {};
      getProjectDetail(params)
        .then((res) => {
          if (res.code) {
            self.detail_data = res.data;
            self.post_data.project = res.data.project;
            self.post_data.fileVos = res.data.fileVos;
            self.review_tips = res.data.tips;
            self.deptName = res.data.project.dwmc;
            // TODO: 陈述列表获取失败时,即无法渲染用户陈述选择(增加获取状态)
            res.data.projectPresents.forEach((item) => {
              self.content_present_list.forEach((list) => {
                if (list.data.length > 0) {
                  list.data.forEach((child) => {
                    if (item.contentPresentId == child.id) {
                      item.checkOption =
                        item.checkOption === 0 || item.checkOption === 1
                          ? item.checkOption.toString()
                          : null;
                      child = Object.assign(child, item);
                    }
                  });
                }
              });
            });
            // if (self.action_type == 'detail') {
            console.log(res.data.examineRecords);
            console.log(self.content_present_list,'self.content_present_list',this.action_type,this.departmentId)
            // }
            for (
              let index = 0;
              index < Object.keys(res.data.examineRecords).length;
              index++
            ) {
              res.data.examineRecords[index + 1].forEach((item) => {
                // console.log(item)
                // if (item.isInfrastructure && item.auditFlag != 10) {
                //   item.auditFlag = "-";
                // }
                switch (item.auditFlag) {
                  case 0:
                    item.auditFlag = "通过";
                    break;
                  case 1:
                    item.auditFlag = "不通过";
                    break;
                  case 2:
                    item.auditFlag = "待办";
                    break;
                  // case 5:
                  // item.auditFlag = "审批人撤回";
                  // break;
                  case 10:
                    item.auditFlag = "基建专员退回";
                    break;
                  case 11:
                    item.auditFlag = "待基建专员审批";
                    break;
                  case 12:
                    item.auditFlag = "-";
                    break;
                  case 15:
                    item.auditFlag = "申请人撤回";
                    break;
                  default:
                    break;
                }
                console.log(item.auditFlag);
              });

              self.examine_records_desserts.push(
                res.data.examineRecords[index + 1]
              );
              self.examine_records_desserts.forEach((item) => {
                item.forEach((item2) => {
                  // console.log(item)
                  item2.isInfrastructureName =
                    item2.isInfrastructure == "0"
                      ? "会审部门审批"
                      : "基建专员意见";
                  // if (item2.isInfrastructure != "0") {
                  //   item.ggName = item2.user;
                  // }
                });
                // console.log(item.length)
                item.lastUser = item[item.length - 1].user;
                let userName = JSON.parse(localStorage.getItem("user")).name;
                item.lastUserShow = item.lastUser == userName ? false : true;
                // console.log(userName,item.lastUser)
                this.lastUserShow = item.lastUserShow;
                // this.lastUserShowArr.push(item.lastUserShow)
              });
              // console.log(this.lastUserShowArr);
              // sessionStorage.setItem('examine_records_desserts',JSON.stringify(self.examine_records_desserts))
            }
          }
          // console.log(self.examine_records_desserts)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 会审批准 〉
     */
    _setProjectAction() {
      let self = this;
      let params = JSON.parse(JSON.stringify(self.post_audit));
      if (!self.$refs.project.validate()) {
        self.$notify({
          title: `请检查必填项`,
          type: "error",
        });
        return;
      }

      if (this.is_opinion) {
        isInfrastructure(self.post_data.project.id).then((res) => {
          if (res.code == "0000") {
            if (res.data) {
              params.projectId = self.post_data.project.id;
              params.auditFlag = 0;
              params.content = this.content;
              // console.log(params);
              setProjectAudit(params)
                .then((res) => {
                  if (res.code == "0000") {
                    self.$notify({
                      title: "操作成功",
                      type: "success",
                    });
                    self._viewToggle({ type: "list" });
                  }
                })
                .catch((err) => {
                  self.$notify({
                    title: "操作失败",
                    message: err.msg,
                    type: "error",
                  });
                  // console.log(222222222222);
                  // console.log(err);
                });
            } else {
              self.$notify({
                title: "操作失败，请先等待审批完成再填写意见！",
                type: "error",
              });
            }
          }
        });
      } else {
        // console.log(11111111);
        params.projectId = self.post_data.project.id;
        params.auditFlag = parseInt(params.auditFlag);
        params.content = self.editContent;
        // console.log(params);
        setProjectAudit(params)
          .then((res) => {
            if (res.code == "0000") {
              self.$notify({
                title: "操作成功",
                type: "success",
              });
              this.isEditNow.push(params.projectId);
              self._viewToggle({ type: "list" });
              sessionStorage.setItem(
                "isEditNow",
                JSON.stringify(this.isEditNow)
              );
            }
          })
          .catch((err) => {
            self.$notify({
              title: "操作失败",
              message: err.msg,
              type: "error",
            });
            // console.log(err);
          });
      }
    },

    /**
     *〈 会审批准 -> 删除 〉
     */
    _delProjectAction() {
      let self = this;
      let params = [];
      self.selected_data.forEach((item) => {
        params.push(item.project.id);
      });
      self.$msgbox
        .confirm("确认删除?")
        .then(() => {
          delProject(params)
            .then((res) => {
              self.selected_data = [];
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._getProjectList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    /**
     *〈 申请记录 -> 下载审批记录及施工图 〉
     */
    _downloadPdf({ id }) {
      // let self = this;
      let params = { id: id };
      getExportProject(params);
    },

    /**
     *〈 下载文件（重命名） 〉
     */
    _getDownloadByUrlAndNameAction(item) {
      getDownloadByUrlAndName(item);
    },

    // 拿到基建专员名单
    getInfrastructureList() {
      let data = {
        currentPage: 1,
        pageSize: 50,
        search: "",
      };
      getInfrastructureList(data).then((res) => {
        if (res.code == "0000") {
          this.infrastructureList = res.data.records;
          // console.log(111111111111);
        }
      });
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    // let self = this;
    // self._getProjectList();
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {
    this.rolesId = localStorage.getItem("rolesId");
  },
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {
    this.getInfrastructureList();
    this._getProjectList();
    sessionStorage.setItem("isEditNow", JSON.stringify([]));
  },
  /* 生命周期 - 更新之前 */
  beforeUpdate() {
    // console.log(this.desserts);
  },
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss">
.detail-table-style {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    background: #f7f8fc;
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
<style lang="scss" scoped></style>
