<template>
  <div class="text-center">
    <v-pagination @input="pageClick($event,10)" v-model="pageData.page" :length="pageData.server_length" :total-visible="7"></v-pagination>
  </div>
</template>

<script>
/* import {} from '@/'; */

export default {
  props: ['pageData'],
  data() {
    return {
      page: 1,
      server_length: 1
    };
  },
  /* import引入的组件 */
  components: {},
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    pageClick(data) {
      this.$emit('jumpAction', data);
    }
  },
  /* 局部过滤器 */
  filters: {},
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {},
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style lang='scss'>
.v-pagination__item {
  min-width: 25px !important;
  height: 25px !important;
  font-size: 0.75rem !important;
}
.v-pagination__navigation {
  width: 25px !important;
  height: 25px !important;
}
</style>
