<template>
  <v-container class="del-pb review-person-config">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card
      v-show="!addShow && isShow"
      elevation="2"
      :class="[
        scroll_data.extended
          ? scroll_data.extended && !child_tab.child_visible
            ? 'card-top card-top-scale pa-0'
            : 'card-top pa-4'
          : 'card-bottom pa-4',
        'card-content',
      ]"
      tile
    >
      <span
        @click.stop="_viewToggle({ type: 'list' })"
        class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5"
        >{{ child_tab.child_title }}</span
      >
      <v-scroll-y-transition hide-on-leave>
        <div v-if="child_tab.child_visible">
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pb">
              <v-col cols="4" class="del-pl">
                <v-text-field
                  v-model="searchInput"
                  single-line
                  class="del-text-field-msg pa-0"
                  outlined
                  label="请输入姓名/工号/部门名称搜索"
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-btn
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                @click="search"
                >搜索</v-btn
              >
            </v-col>
          </v-row>
          <v-row style="margin-top: 20px">
            <v-col class="del-pt">
              <v-btn
                @click.stop="_viewToggle({ type: 'add' })"
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                >新增</v-btn
              >
              <v-btn
                class="ml-4 px-6"
                color="white"
                small
                tile
                elevation="3"
                @click="_delAssessorList"
                >删除</v-btn
              >
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-4 px-6"
                    color="white"
                    small
                    tile
                    >导入</v-btn
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>请选择导入文件</v-toolbar>
                    <v-card-text>
                      <v-form ref="uploadFileForm" v-model="uploadFormValid"
                        ><v-file-input
                          truncate-length="15"
                          v-model="fileInfo"
                        ></v-file-input
                      ></v-form>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="updatedFile(dialog)">确定</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <template>
                <div>
                  <v-data-table
                    hide-default-footer
                    :footer-props="{ 'items-per-page-options': [10] }"
                    no-data-text="暂无数据"
                    v-model="selected_data"
                    :headers="headers"
                    :items="infrastructureList"
                    item-key="id"
                    show-select
                  >
                    <template v-slot:item.action="{ item }">
                      <v-btn
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="#2D9186"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit', item: item })"
                        >交接待办</v-btn
                      >
                    </template>
                  </v-data-table>
                  <BasePageTotal
                    :pageData.sync="pageData1"
                    @jumpAction="_pageTotalAction1"
                  />
                </div>
              </template>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>
    </v-card>

    <!-- 新增 -->
    <v-scroll-y-reverse-transition hide-on-leave>
      <v-card
        v-if="!child_tab.child_visible"
        tile
        elevation="2"
        :class="[
          scroll_data.extended ? 'card-child-top' : 'card-child-bottom',
          'pa-4 card-child-content',
        ]"
      >
        <!-- <div class="w-100 d-flex justify-end">
          <v-btn icon @click.stop="_viewToggle({ type: 'list' })">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div> -->
        <div>
          <v-form ref="project">
            <v-row v-show="child_tab.child_title == '新增'">
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">配置信息</span></span
                >
              </v-col>

              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">姓名</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    @click:append="_deptVisibleToggle"
                    readonly
                    append-icon="mdi-account-multiple"
                    v-model="newAddUserForm.infrastructureName"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="pa-6" v-if="dept_visible">
                <template>
                  <div>
                    <v-text-field
                      v-model="addSearch"
                      @change="addInfrastructure"
                      append-icon="mdi-magnify"
                      label="请输入工号、姓名、部门"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                      hide-default-footer
                      :footer-props="{
                        'items-per-page-text': '',
                        'items-per-page-options': [5],
                      }"
                      :items-per-page="5"
                      no-data-text="暂无数据"
                      :headers="add_headers"
                      :items="addUserList"
                      item-key="sid"
                    >
                      <template v-slot:item.action="{ item }">
                        <v-radio-group
                          class="del-text-field-msg mt-0"
                          v-model="newAddUserForm.infrastructureId"
                        >
                          <v-radio
                            class="del-text-field-msg"
                            :key="item.sid"
                            :value="item.sid"
                            @change="_changePerson(item)"
                          ></v-radio>
                        </v-radio-group>
                      </template>
                    </v-data-table>
                    <BasePageTotal
                      :pageData.sync="pageDataAdd"
                      @jumpAction="add_pageTotalAction1"
                    />
                  </div>
                </template>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">工号</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    disabled
                    v-model="newAddUserForm.infrastructureId"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">部门名称</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-select
                    @change="_getUserGroupAction('')"
                    v-if="action_type == 'add'"
                    item-text="name"
                    item-value="id"
                    v-model="newAddUserForm.deptName"
                    :items="departmentListName"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请选择"
                    dense
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-col>
            </v-row>
            <v-row v-show="child_tab.child_title == '交接待办给'">
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">选择人员</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex flex-row align-center del-pb">
                <v-col cols="4" class="del-pl">
                  <v-text-field
                    v-model="searchInput2"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入姓名、工号"
                    dense
                    clearable
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
                <v-btn
                  class="px-6"
                  color="primary"
                  dark
                  small
                  tile
                  elevation="3"
                  @click="getTnfrastructureList()"
                  >搜索</v-btn
                >
              </v-col>

              <v-col>
                <v-data-table
                  hide-default-footer
                  no-data-text="暂无数据"
                  v-model="selected_data_eidt"
                  :headers="edit_headers"
                  item-key="id"
                  show-select
                  :items="filterInfrastructureList"
                >
                  <!-- :items="filterInfrastructureList" -->
                </v-data-table>
              </v-col>
            </v-row>
            <BasePageTotal
              :pageData.sync="pageDataHandover"
              @jumpAction="handover_pageTotalAction"
            />
          </v-form>
          <!-- <BasePageTotal
            :pageData.sync="pageDataAdd"
            @jumpAction="add_pageTotalAction1"
          /> -->
          <v-row
            justify="center"
            class="py-4"
            v-if="action_type == 'detail' ? false : true"
          >
            <v-btn
              color="white"
              style="color: #333"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              @click="cancel"
              >取消</v-btn
            >
            <v-btn
              v-show="child_tab.child_title == '新增'"
              color="primary"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              @click="save"
              >确定</v-btn
            >
            <v-btn
              v-show="child_tab.child_title == '交接待办给'"
              color="primary"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              @click="saveHandover"
              >确定</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import BasePageTotal from "@/components/base-page-total.vue";

import tools from "@/util/tools";
import {
  getAssessorList,
  setAssessor,
  getInfrastructureList,
  getInfrastructureUserList,
  addNewInfrastructure,
  deleteInfrastructure,
  infrastructureTransfer,
  getInfrastructureTransferList,
  importFiles,
  getDepartmentList,
} from "@/api/api";

import moment from "moment";
export default {
  props: ["scroll_data"],
  data() {
    return {
      pageData: {
        page: 1,
        server_length: 1,
      },
      pageData1: {
        page: 1,
        size: 10,
        total: 0,
        server_length: 0,
      },
      pageDataHandover: {
        page: 1,
        size: 10,
        total: 0,
        server_length: 1,
      },
      pageDataAdd: {
        page: 1,
        server_length: 1,
      },
      rules: {
        required: (value) => !!value || "必填",
      },
      search_data: {}, // 搜索数据
      search_date: [], // 搜索时间
      search_date_visible: false, // 选择时间组件

      post_date: [], // 有效期时间
      post_date_visible: false, // 有效期时间组件
      toggle_index: 1, //视图nav id
      headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
        { text: "姓名", value: "infrastructureName", sortable: false },
        { text: "工号", value: "infrastructureId", sortable: false },
        { text: "剩余待办条数", value: "surplusNum", sortable: false },
        { text: "创建人", value: "createUser", sortable: false },
        { text: "创建时间", value: "createTime", sortable: false },
        { text: "备注", value: "des", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据
      selected_data: [], // 列表已选数据
      add_headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "姓名", value: "name", sortable: false },
        { text: "工号", value: "sid", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
      ], // 列表头
      edit_headers: [
        { text: "姓名", value: "infrastructureName", sortable: false },
        { text: "工号", value: "infrastructureId", sortable: false },
        { text: "部门名称", value: "deptName", sortable: false },
      ],
      dept_desserts: [], // 列表数据
      dept_search: "",
      dept_visible: false,
      child_tab: {
        child_visible: true,
        child_title: "基建专员配置",
      }, // 视图
      post_data: {}, // 提交数据
      dept_list: [
        {
          name: "基建办公室",
          id: "250",
        },
        {
          name: "总务与空间办公室",
          id: "238",
        },
        {
          name: "设施设备维护办公室",
          id: "241",
        },
        {
          name: "安全、健康与环境办公室",
          id: "242",
        },
        {
          name: "网络信息中心",
          id: "260",
        },
      ], //部门列表
      action_type: "list", // 当前操作
      person_list_num: -1, // 总数

      // 新的
      infrastructureList: [], //列表初始数据
      currentPage: 1,
      size: 10,
      total: 0,
      searchInput: "", //搜索关键字
      addShow: false, //是否显示新增
      addCurrentPage: 1,
      addPageSize: 10,
      addTotal: 0,
      addSearch: "",
      userTypeList: ["教师", "学生"],
      addUserList: [],
      selected_data_add: [],
      user: JSON.parse(localStorage.getItem("user")),
      newAddUserForm: {
        certigierName: "",
        createUser: "",
        createUserId: "",
        delFlag: 0,
        deptId: "",
        deptName: "",
        id: 0,
        indateEnd: "",
        indateStart: "",
        infrastructureId: "",
        infrastructureName: "",
      },
      selected_data_eidt: [],
      row: {},
      filterInfrastructureList: [], //筛选过得基建专员
      editSearch: "",
      infrastructureList2: [],
      searchInput2: "",
      uploadFormValid: {},
      fileInfo: "",
      departmentList: [],
      isShow: false,
      departmentListName:[]
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    // 取消
    cancel() {
      this.child_tab.child_visible = true;
      this.child_tab.child_title = "基建专员配置";
      console.log(this.child_tab.child_title);
    },
    // 获取申请人部门列表
    getDepartmentList() {
      getDepartmentList().then((res) => {
        if (res.code == "0000") {
          this.departmentList = res.data;
          res.data.forEach(item=>{
            this.departmentListName.push(item.dept)
          })
        }
      });
    },
    updatedFile(dialog) {
      // console.log(this.fileInfo);
      if (this.$refs.uploadFileForm.validate()) {
        var formData = new window.FormData();
        formData.append("multipartFiles", this.fileInfo);
        importFiles(formData)
          .then((res) => {
            if (res.code == "0000") {
              this.$notify({
                title: "操作成功",
                type: "success",
              });
            }
            this._viewToggle({ type: "list" });
            dialog.value = false;
            this.getInfrastructureList();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 行操作
    _itemAtcion({ type, item }) {
      this.row = item;
      let self = this;
      switch (type) {
        case "edit":
          self._viewToggle({ type: type }, item);
          self.post_data = item;

          console.log(this.row, 11111111111111111111111);
          self.post_date[0] = item.indateStart;
          self.post_date[1] = item.indateEnd;
          break;
        default:
          break;
      }
    },
    // 过滤除了交接以外的基建专员
    getTnfrastructureList() {
      let data = {
        assessorId: this.row.infrastructureId,
        currentPage: this.pageDataHandover.page,
        pageSize: this.pageDataHandover.size,
        search: this.searchInput2,
        transferId: "",
      };
      getInfrastructureTransferList(data).then((res) => {
        if (res.code == "0000") {
          this.filterInfrastructureList = res.data.records;
          this.pageDataHandover.page = res.data.current;
          this.pageDataHandover.size = res.data.size;
          this.pageDataHandover.total = res.data.total;
          this.pageDataHandover.server_length = res.data.pages;
          // console.log(this.filterInfrastructureList, 3333333333333);
        }
      });
    },
    // 交接待办
    saveHandover() {
      // console.log(this.selected_data_eidt);
      if (this.selected_data_eidt.length > 1) {
        this.$notify({
          title: "只能选择一个人！",
          type: "error",
        });
      } else if (this.selected_data_eidt.length == 0) {
        this.$notify({
          title: "请选择一个人！",
          type: "error",
        });
      } else {
        // console.log(this.selected_data_eidt[0],this.row)
        let data = {
          assessorId: this.selected_data_eidt[0].infrastructureId, //接收人ID
          transferId: this.row.infrastructureId, //移交人ID
          currentPage: 1,
          pageSize: 10,
          search: "",
          assessorDeptName: this.selected_data_eidt[0].deptName, //	接收人部门名称
          transferDeptName:  this.row.deptName, //	移交人部门名称
        };
        // console.log(data);
        infrastructureTransfer(data).then((res) => {
          if (res.code == "0000") {
            this.child_tab.child_visible = true;
            this.getInfrastructureList();
            this.selected_data_eidt = [];
            this.$notify({
              title: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    importFile() {},
    rowClick(row) {
      console.log(row);
    },
    // 保存
    save() {
      if (this.child_tab.child_title == "新增") {
        // this.dept_list.forEach((item) => {
        //   if (item.id == this.newAddUserForm.deptId) {
        //     this.newAddUserForm.deptName = item.name;
        //   }
        // });
        // console.log(this.post_date);
        
        let data = { ...this.newAddUserForm };
        this.departmentList.forEach(item=>{
          if(item.dept==this.newAddUserForm.deptName){
            data.deptId=item.deptId;
          }
        })
        data.indateEnd = this.post_date[1];
        data.indateStart = this.post_date[0];
        data.certigierName = this.user.name;
        data.createUserId = this.user.sid;
        data.createUser = this.user.name;
        console.log(data);
        data.id = parseInt(this.newAddUserForm.infrastructureId);
        if (data.infrastructureId == "" || data.infrastructureName == "") {
          this.$notify({
            title: "请选择基建专员！",
            type: "error",
          });
        } else {
          console.log(data,'==>')
          addNewInfrastructure(data).then((res) => {
            if (res.code == "0000") {
              this.$notify({
                title: "操作成功",
                type: "success",
              });
              this.getInfrastructureList();
              this.child_tab.child_visible = true;
              this.newAddUserForm = {
                certigierName: "",

                createUser: "",
                createUserId: "",
                delFlag: 0,
                deptId: "",
                deptName: "",
                id: 0,
                indateEnd: "",
                indateStart: "",
                infrastructureId: "",
                infrastructureName: "",
              };
            }
          });
        }
      }
      this.newAddUserForm = {
        certigierName: "",
        createUser: "",
        createUserId: "",
        delFlag: 0,
        deptId: "",
        deptName: "",
        id: 0,
        indateEnd: "",
        indateStart: "",
        infrastructureId: "",
        infrastructureName: "",
      };
    },
    // 新增人员
    addInfrastructure() {
      let userType = 1;
      this.userTypeList.forEach((item) => {
        if (item == this.userType) {
          userType = item == "教师" ? 1 : 2;
        }
      });
      let data = {
        currentPage: this.addCurrentPage,
        dept: [],
        pageSize: this.addPageSize,
        roleId: 0,
        search: this.addSearch,
        userType,
      };
      getInfrastructureUserList(data).then((res) => {
        if (res.code == "0000") {
          this.addUserList = res.data.records;
          this.addCurrentPage = res.data.current;
          this.addPageSize = res.data.size;
          this.addTotal = res.data.total;
          this.pageDataAdd.server_length = res.data.pages;
        }
      });
    },
    add_pageTotalAction1(page) {
      this.addCurrentPage = page;
      this.addInfrastructure();
    },

    // 试图切换
    _viewToggle({ type }) {
      let self = this;
      self.child_tab.child_visible = false;
      self.action_type = type;
      // self.post_data = {}; // 清空提交数据
      self.set_post = {};
      switch (type) {
        case "add":
          this.addInfrastructure();
          this.getDepartmentList();
          self.child_tab.child_title = "新增";
          break;
        case "addIn":
          this.addInfrastructure();
          self.child_tab.child_title = "新增配置";
          break;
        case "edit":
          this.getTnfrastructureList();
          self.child_tab.child_title = "交接待办给";
          break;
        case "list":
          self.child_tab.child_visible = true;
          self.dept_visible = false;
          self.child_tab.child_title = "基建专员配置";
          self._getAssessorList(1);
          break;
        default:
          break;
      }
      // console.log(this.child_tab.child_title, 11111111111);
    },

    // 日期确认
    _dateConfirm() {
      let self = this;
      self.search_date_visible = false;
      if (self.search_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.search_data.startDate = `${self.search_date[0]} 00:00:00`;
        self.search_data.endDate = `${self.search_date[1]} 23:59:59`;
        self.$refs.search_data_date.save(self.search_date);
      }
    },

    // 日期确认
    _postDateConfirm() {
      let self = this;
      self.post_date_visible = false;
      if (self.post_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.post_data.indateStart = `${self.post_date[0]} 00:00:00`;
        self.post_data.indateEnd = `${self.post_date[1]} 00:00:00`;
        self.$refs.post_data_date.save(self.post_date);
      }
    },

    _deptVisibleToggle() {
      let self = this;
      self.dept_visible = !self.dept_visible;
      self._getUserGroupAction(1);
    },

    _changePerson(item) {
      let self = this;
      if (item) {
        self.$set(self.newAddUserForm, "infrastructureId", item.sid);
        self.$set(self.newAddUserForm, "infrastructureName", item.name);
        self.$set(self.newAddUserForm, "deptName", item.deptName);
        self.$set(self.newAddUserForm, "deptId", item.departmentId);
      }
    },

    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getUserGroupAction(page);
    },

    _pageTotalAction1(page) {
      this.pageData1.page = page;
      this.getInfrastructureList();
    },

    handover_pageTotalAction(page) {
      this.pageDataHandover.page = page;
      this.getTnfrastructureList();
    },

    /**
     *〈 会审人员 -> 获取列表 〉
     */
    _getAssessorList(pages = 1) {
      let self = this;
      let params = {
        currentPage: pages ? pages : 1, // 当前页:默认值1
        pageSize: 10, // 每页条数:默认值10
        search: self.search_data.search, // 搜索框内容
        startDate: self.search_data.startDate, // 开始时间
        endDate: self.search_data.endDate, // 结束时间
      };
      getAssessorList(params)
        .then((res) => {
          if (res.code == "0000") {
            self.desserts = res.data.records;
            // self.pageData1.server_length = res.data.pages;
            res.data.records.forEach((item) => {
              item.indate = `${item.indateStart}～${item.indateEnd}`;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 会审人员 -> 编辑 -> 部门人员列表 〉
     */
    // _getUserGroupAction(pages) {
    //   let self = this;

    //   let params = {
    //     currentPage: pages ? pages : 1,
    //     dept: [self.post_data.deptId],
    //     pageSize: 5,
    //     roleId: 1,
    //     search: self.dept_search,
    //     userType: 1,
    //   };
    //   getDeptUserList(params)
    //     .then((res) => {
    //       if (res.code == "0000") {
    //         self.person_list_num = res.data.total;
    //         self.dept_desserts = res.data.records;
    //         self.pageData.server_length = res.data.pages;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    /**
     *〈 会审人员 -> 提交 〉
     */
    _setReviewPersonAction() {
      let self = this;
      let params = self.post_data;
      params.certigierName = tools.getUsetInfo().name;
      params.indate = undefined;

      params.certigierTime = undefined;
      params.createTime = undefined;
      params.createUser = undefined;
      params.createUserId = undefined;
      params.delFlag = undefined;
      if (params.indateStart == "" || params.indateEnd == "") {
        return self.$notify({
          title: "请填写正确日期",
          type: "error",
        });
      } else if (
        params.assessorId == undefined ||
        params.assessorName == undefined ||
        params.deptId == undefined ||
        params.deptName == undefined ||
        params.indateEnd == undefined ||
        params.indateStart == undefined
      ) {
        return self.$notify({
          title: "请检查填写信息是否完整!",
          type: "error",
        });
      }

      //       params.certigierName = undefined;
      self.$msgbox
        .confirm("确认提交?")
        .then(() => {
          setAssessor(params)
            .then((res) => {
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._viewToggle({ type: "list" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },

    /**
     *〈 会审人员 -> 删除 〉
     */
    _delAssessorList() {
      let self = this;
      let params = [];
      if (self.selected_data.length <= 0) {
        self.$notify({
          title: "没有选择数据",
          type: "error",
        });
        return;
      }
      
      // console.log(self.selected_data,'==>')
      // return
      self.selected_data.forEach((item) => {
        let obj = {}
        obj.infrastructureId=item.infrastructureId;
        obj.deptName=item.deptName;
        params.push(obj);
      });
      self.$msgbox
        .confirm("确认删除?")
        .then(() => {
          deleteInfrastructure(params)
            .then((res) => {
              self.selected_data = [];
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self.getInfrastructureList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    search() {
      this.pageData1.page = 1;
      this.getInfrastructureList();
    },
    // 获取基建专员初始列表数据
    getInfrastructureList() {
      let data = {
        currentPage: this.pageData1.page,
        pageSize: this.pageData1.size,
        search: this.searchInput,
      };
      getInfrastructureList(data).then((res) => {
        if (res.code == "0000") {
          this.pageData1.page = res.data.current;
          this.pageData1.size = res.data.size;
          this.pageData1.total = res.data.total;
          this.pageData1.server_length = res.data.pages;
          console.log(this.pageData1, 1111, res.data.pages);
          this.infrastructureList = res.data.records;
          this.isShow = true;

          this.infrastructureList.forEach((item) => {
            item.createTime = moment(item.createTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });
          // console.log(11111111111111111111)
          // setTimeout(() => {
          //   console.log(this.infrastructureList);
          // }, 500);
        }
      });
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self._getAssessorList(1);
    // self.post_data.deptId = tools.getUsetInfo().departmentId
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {
    this.getInfrastructureList();
  },
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss">
.review-person-config .text-caption {
  display: flex !important;
}
</style>
<style lang="scss" scoped></style>
